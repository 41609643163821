import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import CardColumns from 'react-bootstrap/CardColumns'
import Image from 'react-bootstrap/Image'

const FilmCast = ({ onSelect, filmCast, filmName, filmImage, filmReleaseDate }) => {

    return (
        <>
            <Col className="pt-2 pb-3">
                <Image width="100" className="mx-auto d-block" src={"https://image.tmdb.org/t/p/w100_and_h100_face" + filmImage} roundedCircle />
            </Col>
            <h2>Age of cast</h2>
            <h5 className="pb-4">When "{filmName}" was released</h5>

            <CardColumns>
                {filmCast.map((cast) => {
                    // Work out age of actor when film was released

                    const filmReleaseYear = filmReleaseDate.substring(0, 4);
                    const filmReleaseMonth = filmReleaseDate.substring(5, 7);
                    const filmReleaseDay = filmReleaseDate.substring(8, 10);
                    const editdFilmReleaseDate = filmReleaseMonth + '/' + filmReleaseDay + '/' + filmReleaseYear;
                    const currentYear = new Date().getFullYear();
                    const actorsAge = currentYear - cast.birthday;
                    const filmAge = currentYear - filmReleaseYear;
                    let age = (actorsAge - filmAge);
                    age = CalcAge(cast.birthday, editdFilmReleaseDate);

                    function CalcAge(dob, filmReleaseDate) {
                        const DoB = Date.parse(dob);
                        const FrD = Date.parse(filmReleaseDate);
                        let AgeDays = 0;
                        let AgeYears = 0;
                        //let AgeMonths = 0;
                        const mSecDiff   = FrD - DoB;
                        AgeDays  = mSecDiff / 86400000;
                        AgeYears = AgeDays / 365.24;    
                        AgeYears = Math.floor(AgeYears);
                        //AgeMonths  = (AgeDays - AgeYears * 365.24) / 30.4375;
                        //AgeMonths  = Math.round(AgeMonths * 10) / 10;
                        return AgeYears + ' years old ';
                    }
                    return (

                            <Card className="cursor" key={cast.id} onClick={() => onSelect(cast.id, cast.name, cast.profile_path, cast.birthday)} data-actors-name={cast.name}>
                                <Card.Img className="mt-3 mx-auto d-block rounded-circle" variant="top" src={"https://image.tmdb.org/t/p/w90_and_h90_face" + cast.profile_path}/>
                                <Card.Body>
                                    <Card.Title className="mb-0"><h5 className="mb-0">{cast.name}</h5></Card.Title>
                                </Card.Body>
                                <Card.Footer>
                                    <small className="text-muted">
                                        <h6>Was: {age} </h6>
                                        <p>When {filmName} was released</p>
                                    </small>
                                </Card.Footer>
                            </Card>
                    )
                })}
            </CardColumns>

    </>
    )

}

export default FilmCast