const KnownFor = ({ knownFor }) => {

    return (
        <>
            <p>{knownFor.title}{knownFor.name}</p>
        </>
    )

}

export default KnownFor